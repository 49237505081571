import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import "./InspectionView.css";
import InspectionDetailView from "../InspectionDetailView/InspectionDetailView";
import TabPanelContainer from "../../TabPanel/TabPanelContainer";
import CheckIcon from "@material-ui/icons/Check";
import { Chip } from "@mui/material";

const InspectionView = ({
	inspectionValues,
	inspectionTypes,
	isOpenInspection,
	inspectionItemModalIsOpen,
	setInspectionItemModalIsOpen,
	inspectionDetailSubmitModalIsOpen,
	setInspectionDetailSubmitModalIsOpen,
	callBackRef,
	isleyfurChecks,
}) => {
	const [activeTab, setActiveTab] = useState(0);
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
	const handleCloseSubmitModal = () => {
		setInspectionDetailSubmitModalIsOpen(false);
	};
	const handleOpenSubmitModal = () => {
		setInspectionDetailSubmitModalIsOpen(true);
	};
	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};
	const handleOpenCancelModal = () => {
		setCancelModalIsOpen(true);
	};
	const handleCloseCancelModal = () => {
		setCancelModalIsOpen(false);
	};
	return (
		<div className="inspection-container">
			<Box sx={{ width: "100%" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						value={activeTab}
						onChange={handleTabChange}
						variant="fullWidth"
						aria-label="basic tabs example"
						TabIndicatorProps={{ style: { background: "rgb(83, 83, 83)" } }}
					>
						{inspectionTypes.map((inspectionType) => {
							return (
								<Tab
									key={
										inspectionType.id
											? inspectionType.id
											: inspectionType.inspectionTypeId
									}
									label={
										inspectionType.checked ? (
											<Chip
												style={{ color: "green", backgroundColor: "white" }}
												icon={<CheckIcon style={{ color: "green" }} />}
												label={inspectionType.isName}
											/>
										) : (
											<div style={{ color: "rgb(83, 83, 83)" }}>
												{inspectionType.isName
													? inspectionType.isName
													: inspectionType.inspectionTypeName}
											</div>
										)
									}
								/>
							);
						})}
					</Tabs>
				</Box>
				{inspectionTypes.map((inspectionType, idx) => {
					return (
						<TabPanelContainer
							key={inspectionType.id}
							value={activeTab}
							index={idx}
						>
							<InspectionDetailView
								inspectionValues={inspectionValues}
								inspectionType={inspectionType}
								handleCloseSubmitModal={handleCloseSubmitModal}
								handleOpenSubmitModal={handleOpenSubmitModal}
								inspectionDetailSubmitModalIsOpen={
									inspectionDetailSubmitModalIsOpen
								}
								cancelModalIsOpen={cancelModalIsOpen}
								handleOpenCancelModal={handleOpenCancelModal}
								handleCloseCancelModal={handleCloseCancelModal}
								inspectionTypes={inspectionTypes}
								isOpenInspection={isOpenInspection}
								inspectionItemModalIsOpen={inspectionItemModalIsOpen}
								setInspectionItemModalIsOpen={setInspectionItemModalIsOpen}
								callBackRef={callBackRef}
								isleyfurChecks={isleyfurChecks}
							/>
						</TabPanelContainer>
					);
				})}
			</Box>
		</div>
	);
};

export default InspectionView;
