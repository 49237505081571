import { Modal, Paper } from "@material-ui/core";
import React, { useState } from "react";
import "./InspectionConfirmationModal.css";
import CancelButton from "../CancelButton/CancelButton";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import SaveButton from "../SaveButton/SaveButton";
import pdfService from "../../../services/pdfService";
import InspectionWaitingModal from "../InspectionWaitingModal/InspectionWaitingModal";
const InspectionConfirmationModal = ({
	open,
	handleConfirm,
	handleCancel,
	title,
	id,
	setWaitingModalIsOpen,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	setWaitingModalIsOpen(false);
	const handleViewPDF = () => {
		setIsLoading(true);
		pdfService
			.getPreviewPdfByInspectionId(id)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to fetch PDF");
				}
				return response.blob();
			})
			.then((blob) => {
				var fileURL = URL.createObjectURL(blob);
				window.open(fileURL);
				setIsLoading(false);
			});
		setWaitingModalIsOpen(false);
	};
	return (
		<>
			<Modal
				className="inspection-confirmation-modal"
				open={open}
				onClose={handleCancel}
			>
				<Paper className="inspection-confirmation-modal-paper">
					<div className="inspection-confirmation-modal-paper-title">
						<b>{title}</b>
					</div>
					<div className="inspection-confirmation-modal-buttons">
						<div className="inspection-confirmation-modal-buttons-upper">
							<CancelButton
								handleCancel={() => handleCancel(id)}
								title="Hætta við"
							/>
							<ConfirmationButton
								handleSubmit={() => handleConfirm(id)}
								title="Senda skýrslu"
							/>
						</div>
						<div className="inspection-confirmation-modal-buttons-lower">
							<SaveButton
								handleSave={() => handleViewPDF()}
								title="Skoða skýrslu"
							/>
						</div>
					</div>
					{isLoading && (
						<InspectionWaitingModal text="Þetta er allt að koma!" />
					)}
				</Paper>
			</Modal>
		</>
	);
};
export default InspectionConfirmationModal;
