import React, { useState } from "react";
import "./CompanyContainer.css";
import useGetAllInspectionSchedule from "../../../hooks/getAllInspectionSchedule";
import useGetAllInspectionTypes from "../../../hooks/useGetAllInspectionTypes";
import CompanyView from "../CompanyView/CompanyView";
import useGetAllDistricts from "../../../hooks/useGetAllDistricts";
import { useNavigate } from "react-router";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import useLogOut from "../../../hooks/useLogOut";
import LogoutIcon from "@material-ui/icons/ExitToApp";

const CompanyContainer = () => {
	const { inspectionSchedule, isLoading } = useGetAllInspectionSchedule();
	const { inspectionTypes } = useGetAllInspectionTypes();
	const { districts } = useGetAllDistricts();
	const navigate = useNavigate();
	const { logOut } = useLogOut();
	const user = useSelector((state) => state.user);
	const filteredInspectionTypes = inspectionTypes
		.filter((i) => i.filterCheck === true)
		.map((i) => i.id);
	const filteredDistricts = districts
		.filter((i) => i.filterCheck === true)
		.map((i) => i.id);
	const filteredInspectionSchedule = inspectionSchedule.filter(
		({ inspectionTypeId, districtId }) =>
			filteredInspectionTypes.includes(inspectionTypeId) &&
			filteredDistricts.includes(districtId)
	);
	const [refresh, setRefresh] = useState(false);
	const handleInspectionTypeFilterCheck = (id) => {
		var inspectionTypeToFilter = inspectionTypes.find((i) => i.id === id);
		inspectionTypeToFilter.filterCheck = !inspectionTypeToFilter.filterCheck;
		setRefresh(!refresh);
	};
	const handleDeSelectAllInspectionTypeFilterCheck = () => {
		inspectionTypes.map((iType) => (iType.filterCheck = false));
		setRefresh(!refresh);
	};
	const handleDistrictFilterCheck = (id) => {
		var districtToFilter = districts.find((i) => i.id === id);
		districtToFilter.filterCheck = !districtToFilter.filterCheck;
		setRefresh(!refresh);
	};
	const handleRedirectToOpenInspection = () => {
		const path = "/userinspection";
		navigate(path);
	};
	const handleRedirectToMyReports = () => {
		const path = "/inspectionreports";
		navigate(path);
	};
	const handleCreateNewInspection = () => {
		const path = "/newinspection";
		navigate(path);
	};
	return (
		<div className="company-container">
			<div className="top-bar">
				{user.name}
				<Button onClick={() => logOut(user.ssn)} startIcon={<LogoutIcon />} />
			</div>
			<div className="company-container-buttons">
				<Button
					className="company-container-open-inspection-button"
					onClick={handleRedirectToOpenInspection}
				>
					Opin eftirlit
				</Button>
				<Button
					className="company-container-inspection-report-button"
					onClick={handleRedirectToMyReports}
				>
					Mínar skýrslur
				</Button>
				<Button
					className="company-container-create-new-inspection-button"
					onClick={handleCreateNewInspection}
				>
					Án Búsnúmers
				</Button>
			</div>

			{!isLoading ? (
				<div>
					<CompanyView
						filteredInspectionSchedule={filteredInspectionSchedule}
						inspectionTypes={inspectionTypes}
						districts={districts}
						handleInspectionTypeFilterCheck={handleInspectionTypeFilterCheck}
						handleDistrictFilterCheck={handleDistrictFilterCheck}
						handleDeSelectAllInspectionTypeFilterCheck={
							handleDeSelectAllInspectionTypeFilterCheck
						}
					/>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	);
};

export default CompanyContainer;
