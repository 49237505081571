/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/users/`;

const includeDate = (data) => data.map(
  (data) => ({ ...data, inspectionPerformedDate: new Date(data.inspectionPerformed)})
);

const getInspectionReportsByUserSSN = (ssn, accessToken) => {
  return fetch(apiEndpoint + `${ssn}/reports`, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + accessToken
    },
  })
    .then(handleErrors)
    .then(handleData)
    .then(includeDate)
    .catch((error) => Promise.reject(error));
};

export default { getInspectionReportsByUserSSN };