import React, { useState } from "react";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Paper,
	Select,
	TextareaAutosize,
} from "@material-ui/core";
import CalendarSelection from "../CalendarSelection/CalendarSelection";
import "./InspectionItemModal.css";
import useGetAllJudgements from "../../../hooks/getAllJudgements";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import CancelButton from "../CancelButton/CancelButton";
import InspectionDeviationImage from "../InspectionDeviationImage/InspectionDeviationImage";

const InspectionItemModal = ({
	open,
	handleClose,
	item,
	inspectionDetailValues,
	handleCloseInspectionItemModal,
	changeItem,
	setChangeItem,
}) => {
	const { judgements } = useGetAllJudgements();
	const [webcamIsOpen, setWebcamIsOpen] = useState(false);
	const [changeValue, setChangeValue] = useState(false);
	const handleResetInspectionItem = () => {
		setChangeItem(null);
		handleCloseInspectionItemModal();
	};
	const errors = {
		deadlineToResolve: "",
	};
	const inspectionItemSubmitHandler = () => {
		if (changeItem.judgementId) {
			const itemAlreadySelectedIndex =
				inspectionDetailValues.inspectionDeviations.findIndex(
					(i) => i.itemId === item.id
				);
			if (itemAlreadySelectedIndex > -1) {
				inspectionDetailValues.inspectionDeviations.splice(
					itemAlreadySelectedIndex,
					1
				);
			}
			item.priority = 0;
			inspectionDetailValues.inspectionDeviations.push(changeItem);
			handleResetInspectionItem();
		}
	};
	const handleChangeJudgementId = (e) => {
		changeItem.judgementId = e.target.value;
		setChangeValue(!changeValue);
	};
	const handleChangeCalendarSelection = (e) => {
		changeItem.deadlineToResolve = e.target.value;
		setChangeValue(!changeValue);
	};
	const handleChangeComment = (e) => {
		changeItem.comment = e.target.value;
		setChangeValue(!changeValue);
	};
	const handleJudgementCommentChange = (id) => {
		const isTextUnChanged =
			changeItem.comment === item.deviationText ||
			changeItem.comment === item.deviationSeriousText;
		if (isTextUnChanged || changeItem.comment === "") {
			if (id === 2) {
				changeItem.comment = item.deviationText
					? item.deviationText
					: changeItem.comment;
			} else if (id === 3) {
				changeItem.comment = item.deviationSeriousText
					? item.deviationSeriousText
					: changeItem.comment;
			}
			setChangeValue(!changeValue);
		}
	};
	const helperTextColor = changeItem.comment.length < 800 ? "green" : "red";
	return (
		<Modal className="inspection-item-modal" open={open} onClose={handleClose}>
			<Paper className="inspection-item-modal-paper">
				<div className="inspection-item-modal-paper-title">{item.isName}</div>
				<div className="inspection-item-modal-radio-buttons">
					{changeItem.judgementId === "" && (
						<div style={{ color: "red" }}>{"Velja þarf aðgerð"}</div>
					)}
					<FormControl variant="filled" fullWidth>
						<InputLabel>Veldu aðgerð</InputLabel>
						<Select
							label="Veldu aðgerð"
							name="judgementId"
							value={changeItem.judgementId}
							onChange={handleChangeJudgementId}
						>
							{judgements.map((judgement) => (
								<MenuItem
									key={judgement.id}
									value={judgement.id}
									onClick={() => handleJudgementCommentChange(judgement.id)}
								>
									{judgement.isName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div>
					<CalendarSelection
						values={changeItem}
						valueName="deadlineToResolve"
						handleChange={handleChangeCalendarSelection}
						label="Frestur til"
						disabled={false}
						errors={errors}
					/>
				</div>
				<div className="inspection-item-modal-paper-text">
					<div>Skýring</div>
					<TextareaAutosize
						aria-label="empty textarea"
						value={changeItem.comment}
						onChange={handleChangeComment}
						name="comment"
						placeholder="Skrá skýringu hér ..."
						maxLength={1000}
						style={{
							minWidth: 350,
							minHeight: 100,
							maxWidth: 350,
							maxHeight: 300,
						}}
					/>
					<div style={{ color: helperTextColor }}>
						{changeItem.comment.length} / 1000
					</div>
				</div>
				<div className="inspection-item-detail-image">
					<InspectionDeviationImage
						inspectionDetailValues={changeItem}
						webcamIsOpen={webcamIsOpen}
						setWebcamIsOpen={setWebcamIsOpen}
					/>
				</div>
				<div className="inspection-item-button-area">
					<CancelButton
						handleCancel={handleResetInspectionItem}
						title="Hætta við frávik"
					/>
					<ConfirmationButton
						handleSubmit={inspectionItemSubmitHandler}
						title="Staðfesta frávik"
					/>
				</div>
			</Paper>
		</Modal>
	);
};
export default InspectionItemModal;
