import React from "react";
import { useNavigate } from "react-router-dom";
import useGetToken from "../../hooks/useGetToken";

const SignInOidc = () => {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("code");
	const fn = () => {
		const path = "/company";
		navigate(path);
	};
	const { getToken, error } = useGetToken(fn);
	if (error === null) {
		setTimeout(() => {
			getToken(code);
		}, "1000");
	}
	return (
		<>
			<div>
				<p>Completing authentication</p>
			</div>
		</>
	);
};
export default SignInOidc;
