import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import InspectionContainer from "./components/Inspection/InspectionContainer/InspectionContainer";
import InspectionTypeContainer from "./components/Inspection/InspectionTypeContainer/InspectionTypeContainer";
import CompanyContainer from "./components/Company/CompanyContainer/CompanyContainer";
import CompanyDetailsContainer from "./components/Company/CompanyDetailsContainer/CompanyDetailsContainer";
import LandingPage from "./components/LandingPage/LandingPage";
import SignInOidc from "./components/Login/SignInOidc";
import OpenInspectionByUserView from "./components/Inspection/OpenInspectionByUserView/OpenInspectionByUserView";
import InspectionReportsContainer from "./components/Inspection/InspectionReportsContainer/InspectionReportsContainer";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import InspectionFollowUpContainer from "./components/Inspection/InspectionFollowUp/InspectionFollowUpContainer";
import NewInspectionContainer from "./components/Inspection/NewInspectionContainer/NewInspectionContainer";

function App() {
	return (
		<div className="App">
			<div id="main" className="main sidebar-hide">
				<BrowserRouter>
					<Routes>
						<Route path="" element={<LandingPage />} />
						<Route path="signinoidc" element={<SignInOidc />} />
						<Route
							path="company"
							element={
								<PrivateRoute>
									<CompanyContainer />
								</PrivateRoute>
							}
						/>
						<Route
							path="preparation"
							element={
								<PrivateRoute>
									<InspectionTypeContainer />
								</PrivateRoute>
							}
						/>
						<Route
							path="inspection"
							element={
								<PrivateRoute>
									<InspectionContainer />
								</PrivateRoute>
							}
						/>
						<Route
							path="followupinspection"
							element={<InspectionFollowUpContainer />}
						/>
						<Route
							path="company/:id"
							element={
								<PrivateRoute>
									<CompanyDetailsContainer />
								</PrivateRoute>
							}
						/>
						<Route
							path="userinspection"
							element={
								<PrivateRoute>
									<OpenInspectionByUserView />
								</PrivateRoute>
							}
						/>
						<Route
							path="inspectionreports"
							element={
								<PrivateRoute>
									<InspectionReportsContainer />
								</PrivateRoute>
							}
						/>
						<Route
							path="newinspection"
							element={
								<PrivateRoute>
									<NewInspectionContainer />
								</PrivateRoute>
							}
						/>
					</Routes>
				</BrowserRouter>
			</div>
		</div>
	);
}

export default App;
