import CancelButton from "../CancelButton/CancelButton";
import ConfirmationButton from "../ConfirmationButton/ConfirmationButton";
import "./InspectionAdditionalInfoView.css";
import InspectionAdditionalInfoInput from "../InspectionAdditionalInfoInput/InspectionAdditionalInfoInput";

const InspectionAdditionalInfoView = ({
	inspectionValues,
	handleChange,
	errors,
	handleFinish,
	handleCancelInspection,
}) => {
	return (
		<div className="inspection-additional-info-view">
			<InspectionAdditionalInfoInput
				inspectionValues={inspectionValues}
				handleChange={handleChange}
				errors={errors}
			/>
			{errors.inspectionDetails && (
				<span style={{ color: "red" }}>{errors.inspectionDetails}</span>
			)}
			<div className="inspection-additional-info-buttons">
				<div className="inspection-additional-info-buttons-upper">
					<CancelButton
						title="Hætta skráningu"
						handleCancel={handleCancelInspection}
					/>
					<ConfirmationButton title="Vista/Klára" handleSubmit={handleFinish} />
				</div>
			</div>
		</div>
	);
};

export default InspectionAdditionalInfoView;
