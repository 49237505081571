/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import { SET_LOGIN_USER, REFRESH_TOKEN, SET_LOGOUT_USER } from "../types";
const initState = {
	name: null,
	title: null,
	district: null,
	ssn: null,
	inspectionTypes: null,
	tokenValidTo: null,
	accessToken: null,
};

export default (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_LOGIN_USER: {
			return {
				...state,
				name: payload.name,
				ssn: payload.ssn,
				title: payload.title,
				district: payload.district,
				inspectionTypes: payload.inspectionTypes,
				accessToken: payload.accessToken,
				tokenValidTo: payload.accessToken.validTo,
			};
		}

		case REFRESH_TOKEN: {
			return {
				...state,
				accessToken: payload,
				tokenValidTo: payload.validTo,
			};
		}
		case SET_LOGOUT_USER: {
			return {
				...state,
				name: null,
				ssn: null,
				title: null,
				district: null,
				inspectionTypes: null,
				accessToken: null,
				tokenValidTo: null,
			};
		}
		default:
			return state;
	}
};
