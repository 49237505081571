/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/followups`;

const getFollowUpInspectionById = (id, accessToken) => {
	return fetch(apiEndpoint + `/${id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};

const createFollowUpInspection = async (inspection, accessToken) => {
	return fetch(apiEndpoint, {
		method: "POST",
		body: JSON.stringify(inspection),
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};

const updateFollowUpInspection = async (inspection, id, accessToken) => {
	return fetch(apiEndpoint + `/${id}`, {
		method: "PUT",
		body: JSON.stringify(inspection),
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};

export default {
	createFollowUpInspection,
	updateFollowUpInspection,
	getFollowUpInspectionById,
};
