/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/companies`;

const getFarmById = (id, accessToken) => {
	return fetch(apiEndpoint + `/${id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};
const getIsleyfurChecksById = (id, accessToken) => {
	return fetch(apiEndpoint + `/${id}/previouschecks`, {
		method: "GET",
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};

const getFollowUpChecksById = (id, accessToken) => {
	return fetch(apiEndpoint + `/${id}/followupinspectiondeviations`, {
		method: "GET",
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};

const getIndividualBySSN = (ssn, accessToken) => {
	return fetch(apiEndpoint + `/ssnfirstsix/${ssn}`, {
		method: "GET",
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};

const getIsleyfurpdfreport = async (id, accessToken) => {
	return fetch(apiEndpoint + `/isleyfurpdfreport/${id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			Accept: "application/pdf", // Set the appropriate Accept header
			Authorization: "Bearer " + accessToken,
		},
	}).catch((error) => Promise.reject(error));
};

export default {
	getFarmById,
	getIsleyfurChecksById,
	getFollowUpChecksById,
	getIndividualBySSN,
	getIsleyfurpdfreport,
};
