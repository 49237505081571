const inspectionFollowUpValidation = (values) => {
	const {
		billingHour,
		billingHourExtra,
		billingHourPreperation,
		billingHourTransport,
		inspectionPerformed,
		presentName,
	} = values;
	const errors = {};

	if (inspectionPerformed === "") {
		errors.inspectionPerformed = "Velja þarf dagsetningu";
	}
	if (isNaN(billingHour)) {
		errors.billingHour = "Gildi þarf að vera tala";
	}
	if (isNaN(billingHourPreperation)) {
		errors.billingHourPreperation = "Gildi þarf að vera tala";
	}

	if (isNaN(billingHourExtra)) {
		errors.billingHourExtra = "Gildi þarf að vera tala";
	}

	if (isNaN(billingHourTransport)) {
		errors.billingHourTransport = "Gildi þarf að vera tala";
	}
	if (presentName.length < 1) {
		errors.presentName = "Skrá þarf viðstaddan";
	}
	return errors;
};

export default inspectionFollowUpValidation;
