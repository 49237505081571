const inspectionValidation = (values) => {
	const {
		billingHour,
		billingHourPreperation,
		billingHourExtra,
		billingHourTransport,
		presentName,
		inspectionPerformed,
		inspectionDetails,
		inspectionTypes,
	} = values;
	const errors = {};
	const allInspectionTypesChecked = inspectionTypes.every(
		(type) => type.checked === true
	);

	if (inspectionPerformed === "") {
		errors.inspectionPerformed = "Velja þarf dagsetningu";
	}
	if (billingHour.length < 1) {
		errors.billingHour = "Skrá þarf tíma á framkvæmd";
	}

	if (billingHourPreperation.length < 1) {
		errors.billingHourPreperation = "Skrá þarf tíma á undirbúning";
	}

	if (billingHourTransport.length < 1) {
		errors.billingHourTransport = "Skrá þarf tíma á akstur";
	}

	if (billingHourExtra.length < 1) {
		errors.billingHourExtra = "Skrá þarf tíma á frágang";
	}

	if (isNaN(billingHour)) {
		errors.billingHour = "Gildi þarf að vera tala";
	}

	if (isNaN(billingHourPreperation)) {
		errors.billingHourPreperation = "Gildi þarf að vera tala";
	}

	if (isNaN(billingHourExtra)) {
		errors.billingHourExtra = "Gildi þarf að vera tala";
	}

	if (isNaN(billingHourTransport)) {
		errors.billingHourTransport = "Gildi þarf að vera tala";
	}

	if (presentName.length < 1) {
		errors.presentName = "Skrá þarf viðstaddan";
	}
	if (Object.keys(inspectionDetails).length === 0) {
		errors.inspectionDetails = "Það þarf að staðfesta andlag";
	}
	if (inspectionDetails.length !== inspectionTypes.length) {
		errors.inspectionDetails =
			"Ekki hefur verið staðfest andlag fyrir allar dýrategundir";
	}
	if (!allInspectionTypesChecked) {
		errors.inspectionDetails =
			"Ekki hefur verið staðfest andlag fyrir allar dýrategundir";
	}
	return errors;
};

export default inspectionValidation;
