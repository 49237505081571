import { useState, useEffect } from "react";
import companyService from "../services/companyService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useGetFarmById = (id) => {
	const [farm, setFarm] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const user = useSelector((state) => state.user);
	const [isTokenExpired, setIsTokenExpired] = useState(
		new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date()
	);
	const { refreshToken } = useRefreshToken();
	if (isTokenExpired) {
		refreshToken(user);
		setIsTokenExpired(user.tokenValidTo < new Date());
	}

	useEffect(() => {
		!isTokenExpired &&
			companyService
				.getFarmById(id, user.accessToken.rawData)
				.then((farm) => {
					farm.inspectionTypeInInspection = [];
					setFarm(farm);
					setError(null);
				})
				.catch((error) => setError(error))
				.finally(() => {
					setIsLoading(false);
				});
	}, [id, user.accessToken.rawData, isTokenExpired]);
	return { farm, isLoading, error };
};
export default useGetFarmById;
