import { useState, useEffect } from "react";
import inspectionTypeService from "../services/inspectionTypeService";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";

const useGetAllInspectionTypes = () => {
	const [inspectionTypes, setInspectionTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const user = useSelector((state) => state.user);
	const [isTokenExpired, setIsTokenExpired] = useState(
		new Date(user.tokenValidTo ? user.tokenValidTo : "2122-03-25") < new Date()
	);
	const { refreshToken } = useRefreshToken();
	if (isTokenExpired) {
		refreshToken(user);
		setIsTokenExpired(user.tokenValidTo < new Date());
	}

	useEffect(() => {
		!isTokenExpired &&
			inspectionTypeService
				.getAllInspectionTypes(user.accessToken.rawData)
				.then((inspectionTypes) => {
					inspectionTypes.forEach((inspectionType) => {
						inspectionType.selected = false;
						inspectionType.checked = false;
						inspectionType.isOnFarm = false;
						inspectionType.filterCheck = true;

						if (user.inspectionTypes.includes(inspectionType.id)) {
						}
					});
					setInspectionTypes(inspectionTypes);
					setError(null);
				})
				.catch((error) => setError(error))
				.finally(() => {
					setIsLoading(false);
				});
	}, [user, isTokenExpired]);
	return { inspectionTypes, isLoading, error };
};
export default useGetAllInspectionTypes;
