/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { handleErrors, handleData } from "./serviceHandlers";
import endpoint from "./endpoint";

const apiEndpoint = `${endpoint}/api/inspectiontypes`;

const getAllInspectionTypes = (accessToken) => {
	return fetch(apiEndpoint, {
		method: "GET",
		mode: "cors",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	})
		.then(handleErrors)
		.then(handleData)
		.catch((error) => Promise.reject(error));
};

export default { getAllInspectionTypes };
